import React from "react";
import ReactDOM from "react-dom";
import App from "./components/App";
import axios from "axios";
import { Provider } from "react-redux";
import { store } from "store";
import "semantic-ui-css/semantic.min.css";
import "./index.css";
import { setUser } from "store/userSlice";

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      if (error.response.status === 401) {
        store.dispatch(setUser(null));
        localStorage.removeItem("token");
        window.location.replace("/login");
      }
      console.log(
        `axios global error message: ${error.response.data?.message}`
      );
    } else {
      console.log("axios global error message: unknown error");
    }
  }
);

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);
