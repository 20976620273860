import _ from "lodash";

export enum usersModalEnum {
  None,
  Level,
  Points,
  Balance,
}

export const levelOptions = _.range(12).map((item) => {
  return {
    key: item.toString(),
    value: item,
    text: item.toString(),
  };
});

const symbols = ["KSM", "DOT", "ROC", "αOSN", "βOSN"];

export const symbolOptions = symbols.map((item) => ({
  key: item,
  value: item,
  text: item,
}));
