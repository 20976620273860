import axios from "axios";

export async function login(values: {
  usernameOrEmail: string;
  password: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
}): Promise<any> {
  try {
    const res = await axios.post("auth/login", values);
    const accessToken = res.data.accessToken;
    localStorage.setItem("token", accessToken);
    axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
    return res.data;
  } catch (err) {
    if (err.response) {
      return {
        error: err.response.data,
      };
    } else {
      return { error: { message: "unkonwn error" } };
    }
  }
}

export function logout(): void {
  localStorage.removeItem("token");
  axios.defaults.headers.common["Authorization"] = null;
}

export async function isAdmin(): Promise<boolean> {
  try {
    const res = await axios.get("user/profile");
    return res.data.level >= 11;
  } catch {
    return false;
  }
}
