import React, { useState, useEffect, useRef } from "react";
import { useQuery, useMutation } from "react-query";
import axios from "axios";
import {
  Button,
  Table,
  Pagination,
  Modal,
  Dropdown as DropdownDefault,
} from "semantic-ui-react";
import { Formik, Form } from "formik";
import { Input, Dropdown } from "components/Form";
import TableInfo from "components/TableInfo";
import dayjs from "dayjs";

const DEFAULT_PAGE_SIZE = 10;

export default function Posts(): JSX.Element {
  const postsCategory = useRef(localStorage.getItem("postsCategory"));

  const [categoryOptions, setCategoryOptions] = useState<
    { key: string; text: string; value: string }[]
  >([]);
  const [search, setSearch] = useState<{
    title: string;
    author: string;
    category: string;
    page: number;
    page_size: number;
  }>({
    title: "",
    author: "",
    category: postsCategory.current ?? "",
    page: 1,
    page_size: DEFAULT_PAGE_SIZE,
  });
  const [modalOpen, setModalOpen] = useState(false);
  const [changePostId, setChangePostId] = useState("");
  const [changeCategoryId, setChangeCategoryId] = useState("");

  const { data: categories } = useQuery<
    {
      _id: string;
      name: string;
    }[]
  >("categories", async () => {
    const { data } = await axios.get("categories");
    return data;
  });

  const { isLoading, data, refetch } = useQuery(["posts", search], async () => {
    const { data } = await axios.get("search/posts", {
      params: { ...search },
    });
    return data;
  });

  const pinMutation = useMutation(
    ({ postId, pin }: { postId: string; pin: boolean }) =>
      axios.post(`/posts/${postId}/setpin`, { pin }),
    {
      onSuccess: () => {
        refetch();
      },
    }
  );

  const categoryMutation = useMutation(
    ({ postId, categoryId }: { postId: string; categoryId: string }) =>
      axios.post(`/posts/${postId}/move`, {
        categoryId,
      }),
    {
      onSuccess: () => {
        refetch();
        setModalOpen(false);
      },
    }
  );

  useEffect(() => {
    if (categories) {
      setCategoryOptions(
        categories.map((item) => ({
          key: item._id,
          text: item.name,
          value: item._id,
        }))
      );
    }
  }, [categories]);

  const page = data?.page;
  const total = Math.ceil(data?.total / data?.pageSize);

  return (
    <>
      <Formik
        initialValues={{
          title: "",
          author: "",
          category: postsCategory.current ?? "",
        }}
        onSubmit={(values) => {
          localStorage.setItem("postsCategory", values.category);
          setSearch({ ...values, page: 1, page_size: DEFAULT_PAGE_SIZE });
        }}
      >
        <Form className="searchWrapper">
          <label>Title</label>
          <Input name="title" />
          <label>Author</label>
          <Input name="author" />
          <label>Category</label>
          <Dropdown
            id="category"
            name="category"
            options={categoryOptions}
            clearable
          />
          <Button type="submit">Search</Button>
        </Form>
      </Formik>
      {isLoading ? (
        <TableInfo content="Loading..." />
      ) : !data || !data.items || data.items.length === 0 ? (
        <TableInfo content="No data" />
      ) : (
        <>
          <Table basic unstackable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Author</Table.HeaderCell>
                <Table.HeaderCell>Title</Table.HeaderCell>
                <Table.HeaderCell>Category</Table.HeaderCell>
                <Table.HeaderCell>LastDate</Table.HeaderCell>
                <Table.HeaderCell>Comments</Table.HeaderCell>
                <Table.HeaderCell>Bounty</Table.HeaderCell>
                <Table.HeaderCell>PinToTop</Table.HeaderCell>
                <Table.HeaderCell>Action</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {data.items.map(
                (
                  item: {
                    author: { username: string };
                    title: string;
                    category: { name: string; _id: string };
                    updatedAt: string;
                    commentsCount: number;
                    bounty: { amount: string; symbol: string };
                    pinToTop: boolean | null;
                    id: string;
                  },
                  index: number
                ) => (
                  <Table.Row key={index}>
                    <Table.Cell>{item.author.username}</Table.Cell>
                    <Table.Cell>{item.title}</Table.Cell>
                    <Table.Cell>{item.category.name}</Table.Cell>
                    <Table.Cell>
                      {dayjs(item.updatedAt).format("YYYY/MM/DD HH:mm")}
                    </Table.Cell>
                    <Table.Cell>{item.commentsCount}</Table.Cell>
                    <Table.Cell>{`${
                      item.bounty
                        ? `${item.bounty?.amount} ${item.bounty?.symbol}`
                        : ""
                    }`}</Table.Cell>
                    <Table.Cell>
                      <Button
                        onClick={() => {
                          pinMutation.mutate({
                            postId: item.id,
                            pin: !item.pinToTop,
                          });
                        }}
                      >
                        {item.pinToTop ? "Unpin" : "Pin"}
                      </Button>
                    </Table.Cell>
                    <Table.Cell>
                      <Button
                        icon="edit"
                        onClick={() => {
                          setChangePostId(item.id);
                          setChangeCategoryId(item.category._id);
                          setModalOpen(true);
                        }}
                      />
                    </Table.Cell>
                  </Table.Row>
                )
              )}
            </Table.Body>
          </Table>
          <div className="paginationWrapper">
            <div style={{ marginLeft: "1rem" }}>
              共计{data?.total ?? 0}条记录
            </div>
            <Pagination
              activePage={page}
              totalPages={total}
              onPageChange={(e, { activePage }) =>
                setSearch({ ...search, page: Number(activePage) })
              }
            />
          </div>
        </>
      )}
      <Modal size="mini" open={modalOpen} onClose={() => setModalOpen(false)}>
        <Modal.Header>Change Category</Modal.Header>
        <Modal.Content>
          <DropdownDefault
            selection
            options={categoryOptions}
            value={changeCategoryId}
            onChange={(e, { value }) => {
              setChangeCategoryId(value as string);
            }}
          />
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={() => setModalOpen(false)}>
            No
          </Button>
          <Button
            positive
            onClick={() =>
              categoryMutation.mutate({
                postId: changePostId,
                categoryId: changeCategoryId,
              })
            }
          >
            Yes
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
}
