import jwt_decode from "jwt-decode";
import axios from "axios";

interface UserInterface {
  username: string;
  email: string;
}

export function useToken(): UserInterface | void {
  try {
    const token = localStorage.getItem("token");
    if (token) {
      const decoded: UserInterface & {
        exp: number;
      } = jwt_decode(token);
      if (decoded && decoded.exp) {
        if (decoded.exp * 1000 < new Date().valueOf()) {
          localStorage.removeItem("token");
          return;
        } else {
          axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
          return {
            username: decoded.username,
            email: decoded.email,
          };
        }
      }
    }
  } catch (err) {
    console.log(err);
    return;
  }
}
