import React from "react";
import { Modal, Button } from "semantic-ui-react";
import { Formik, Form } from "formik";
import { usersModalEnum, levelOptions } from "constant";
import { Dropdown } from "components/Form";

export default function LevelModal({
  modalOpen,
  setModalOpen,
  mutation,
  level,
}: {
  modalOpen: usersModalEnum;
  setModalOpen: (modalOpen: usersModalEnum) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  mutation: any;
  level: number | undefined;
}): JSX.Element {
  return (
    <Modal
      size="mini"
      open={modalOpen === usersModalEnum.Level}
      onClose={() => setModalOpen(usersModalEnum.None)}
    >
      <Modal.Header>Level</Modal.Header>
      <Modal.Content>
        <Formik
          initialValues={{
            level: level ?? 0,
          }}
          onSubmit={(values) => {
            mutation.mutate(values.level);
          }}
        >
          <Form className="modalForm">
            <Dropdown
              selection
              options={levelOptions}
              name="level"
              id="level"
            />
            <Button type="submit">Confirm</Button>
          </Form>
        </Formik>
      </Modal.Content>
    </Modal>
  );
}
