import React from "react";
import { Dropdown, Menu } from "semantic-ui-react";
import { usersModalEnum } from "constant";

export default function ActionDropdown({
  setModalOpen,
  onFocus,
}: {
  setModalOpen: (modalOpen: usersModalEnum) => void;
  onFocus: () => void;
}): JSX.Element {
  const options = [
    {
      key: usersModalEnum.Level,
      text: "Level",
      value: usersModalEnum.Level,
      active: false,
      selected: false,
      onClick: () => setModalOpen(usersModalEnum.Level),
    },
    {
      key: usersModalEnum.Points,
      text: "Points",
      value: usersModalEnum.Points,
      active: false,
      selected: false,
      onClick: () => setModalOpen(usersModalEnum.Points),
    },
    {
      key: usersModalEnum.Balance,
      text: "Balance",
      value: usersModalEnum.Balance,
      active: false,
      selected: false,
      onClick: () => setModalOpen(usersModalEnum.Balance),
    },
  ];

  return (
    <Menu compact>
      <Dropdown text="Action" options={options} item onFocus={onFocus} />
    </Menu>
  );
}
