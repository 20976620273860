import React from "react";
import { useField } from "formik";
import { Input } from "semantic-ui-react";

export default function FormInput({
  ...props
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
{ [x: string]: any } & { name: string }): JSX.Element {
  const [field, meta] = useField(props);
  return (
    <>
      <Input {...field} {...props} />
      {meta.touched && meta.error ? (
        <div className="FormError">{meta.error}</div>
      ) : null}
    </>
  );
}
