import React, { useState } from "react";
import { Modal, Button } from "semantic-ui-react";
import { Formik, Form } from "formik";
import { Input } from "components/Form";

export default function PointsModal({
  modalOpen,
  setModalOpen,
  mutation,
}: {
  modalOpen: boolean;
  setModalOpen: (modalOpen: boolean) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  mutation: any;
}): JSX.Element {
  const [isDelete, setIsDelete] = useState(false);
  return (
    <Modal size="mini" open={modalOpen} onClose={() => setModalOpen(false)}>
      <Modal.Header>Role</Modal.Header>
      <Modal.Content>
        <Formik
          initialValues={{
            username: "",
          }}
          validate={(values) => {
            const errors = {} as { points: string };
            if (!values.username) {
              errors.points = "Required";
            }
            return errors;
          }}
          onSubmit={(values) => {
            mutation.mutate({
              username: values.username,
              role: "admin",
              isDelete,
            });
          }}
        >
          {(formikProps) => (
            <Form className="modalForm">
              <Input name="username" placeholder="username" />
              <div style={{ display: "flex" }}>
                <Button
                  type="button"
                  onClick={() => {
                    setIsDelete(false);
                    formikProps.submitForm();
                  }}
                >
                  Confirm
                </Button>
                <Button
                  type="button"
                  onClick={() => {
                    setIsDelete(true);
                    formikProps.submitForm();
                  }}
                  negative
                >
                  Delete
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Content>
    </Modal>
  );
}
