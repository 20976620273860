import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { Route, useLocation } from "react-router-dom";
import { Header, Menu, Dropdown, Divider } from "semantic-ui-react";
import routes from "../../constant/routes";
import { userSelector, setUser } from "store/userSlice";
import { useToken } from "hooks";
import { useAppSelector, useAppDispatch } from "store/hooks";
import { logout, isAdmin } from "service/api";

export default function Layout({
  children,
}: {
  children: JSX.Element;
}): JSX.Element {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const user = useAppSelector(userSelector);
  const token = useToken();
  const [admin, setAdmin] = useState<boolean | null>(null);

  useEffect(() => {
    if (!token) {
      history.replace("/login");
    } else if (!user) {
      dispatch(setUser(token));
    }
  }, [token, user]);

  useEffect(() => {
    if (user) {
      isAdmin().then((res) => setAdmin(res));
    }
  }, [user]);

  if (!user) return <div />;

  return (
    <div style={{ height: "100vh", display: "flex" }}>
      <Menu inverted vertical style={{ height: "100%" }}>
        {routes.map((item, index) => (
          <Menu.Item
            key={index}
            name={item.name}
            active={item.path === location.pathname}
            onClick={() => {
              item.path !== location.pathname && history.push(item.path);
            }}
          />
        ))}
      </Menu>
      <div style={{ flexGrow: 1, display: "flex", flexDirection: "column" }}>
        <div
          style={{
            padding: "1rem 1rem 0",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Header as="h2" style={{ marginBottom: 0 }}>
            {routes.map((item, index) => (
              <Route key={index} exact path={item.path}>
                {item.title}
              </Route>
            ))}
          </Header>
          <Dropdown text={user?.username ?? user?.email}>
            <Dropdown.Menu direction="left">
              <Dropdown.Item
                text="Logout"
                onClick={() => {
                  logout();
                  dispatch(setUser(null));
                }}
              />
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <Divider />
        <section style={{ flexGrow: 1, overflow: "scroll" }}>
          {admin === null ? (
            <div style={{ textAlign: "center" }}>Loading...</div>
          ) : admin === false ? (
            <div style={{ textAlign: "center" }}> Only admin can access</div>
          ) : (
            children
          )}
        </section>
      </div>
    </div>
  );
}
