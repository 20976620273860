import Posts from "components/Posts";
import Users from "components/Users";
import Nodes from "components/Nodes";

const routes = [
  {
    path: "/",
    name: "Posts",
    title: "Posts Management",
    component: Posts,
  },
  {
    path: "/users",
    name: "Users",
    title: "Users Management",
    component: Users,
  },
  {
    path: "/nodes",
    name: "Nodes",
    title: "Nodes Management",
    component: Nodes,
  },
];

export default routes;
