import React, { useState } from "react";
import { useQuery, useMutation } from "react-query";
import axios from "axios";
import { Table, Button } from "semantic-ui-react";
import TableInfo from "components/TableInfo";
import RoleModal from "./RoleModal";

export default function Nodes(): JSX.Element {
  const [modalOpen, setModalOpen] = useState(false);
  const [currentCategoryId, setCurrentCategoryId] = useState<string | null>(
    null
  );
  const { isLoading, data, refetch } = useQuery(["nodes"], async () => {
    const { data } = await axios.get("categories", {
      params: { userroles: 1 },
    });
    return data;
  });

  const roleMutation = useMutation(
    (data: { username: string; role: string; isDelete: boolean }) =>
      axios.post(
        `/users/${data.username}/${data.isDelete ? "delrole" : "setrole"}`,
        {
          categoryId: currentCategoryId,
          role: data.role,
        }
      ),
    {
      onSuccess: () => {
        refetch();
        setModalOpen(false);
      },
    }
  );

  return (
    <>
      {isLoading ? (
        <TableInfo content="Loading" />
      ) : !data || data.length === 0 ? (
        <TableInfo content="No data" />
      ) : (
        <>
          <Table basic unstackable selectable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Node</Table.HeaderCell>
                <Table.HeaderCell>Action</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {(data || []).map(
                (
                  item: {
                    id: string;
                    name: string;
                    userRoles: { user: { username: string } }[];
                  },
                  index: number
                ) => (
                  <Table.Row key={index}>
                    <Table.Cell>{item.name}</Table.Cell>
                    <Table.Cell>
                      <Button
                        onClick={() => {
                          setCurrentCategoryId(item.id);
                          setModalOpen(true);
                        }}
                      >
                        Edit
                      </Button>
                      {(item.userRoles || [])
                        .map((item) => item.user.username)
                        .join(" ")}
                    </Table.Cell>
                  </Table.Row>
                )
              )}
            </Table.Body>
          </Table>
        </>
      )}
      {modalOpen && (
        <RoleModal
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          mutation={roleMutation}
        />
      )}
    </>
  );
}
