import React from "react";

import { Modal, Button } from "semantic-ui-react";
import { Formik, Form } from "formik";
import { usersModalEnum, symbolOptions } from "constant";
import { Input, Dropdown } from "components/Form";

export default function BalanceModal({
  modalOpen,
  setModalOpen,
  mutation,
}: {
  modalOpen: usersModalEnum;
  setModalOpen: (modalOpen: usersModalEnum) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  mutation: any;
}): JSX.Element {
  return (
    <Modal
      size="mini"
      open={modalOpen === usersModalEnum.Balance}
      onClose={() => setModalOpen(usersModalEnum.None)}
    >
      <Modal.Header>Balance</Modal.Header>
      <Modal.Content>
        <Formik
          initialValues={{
            symbol: "",
            amount: "",
          }}
          validate={(values) => {
            const errors = {} as { symbol: string; amount: string };
            const amount = parseFloat(values.amount);
            if (
              values.amount === null ||
              values.amount === undefined ||
              values.amount === ""
            ) {
              errors.amount = "Required";
            } else if (Math.floor(amount) !== amount) {
              errors.amount = "Must be an integer";
            } else if (amount === 0) {
              errors.amount = "Cannot be 0";
            }
            if (!values.symbol) {
              errors.symbol = "Required";
            }
            return errors;
          }}
          onSubmit={(values) => {
            mutation.mutate(values);
          }}
        >
          <Form className="modalForm">
            <Dropdown
              selection
              options={symbolOptions}
              name="symbol"
              id="symbol"
              placeholder="symbol"
            />
            <Input name="amount" placeholder="0" type="number" />
            <Button type="submit">Confirm</Button>
          </Form>
        </Formik>
      </Modal.Content>
    </Modal>
  );
}
