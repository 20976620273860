import React from "react";
import { Modal, Button } from "semantic-ui-react";
import { Formik, Form } from "formik";
import { usersModalEnum } from "constant";
import { Input } from "components/Form";

export default function PointsModal({
  modalOpen,
  setModalOpen,
  mutation,
}: {
  modalOpen: usersModalEnum;
  setModalOpen: (modalOpen: usersModalEnum) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  mutation: any;
}): JSX.Element {
  return (
    <Modal
      size="mini"
      open={modalOpen === usersModalEnum.Points}
      onClose={() => setModalOpen(usersModalEnum.None)}
    >
      <Modal.Header>Points</Modal.Header>
      <Modal.Content>
        <Formik
          initialValues={{
            points: "",
          }}
          validate={(values) => {
            const errors = {} as { points: string };
            const points = parseFloat(values.points);
            if (
              values.points === null ||
              values.points === undefined ||
              values.points === ""
            ) {
              errors.points = "Required";
            } else if (Math.floor(points) !== points) {
              errors.points = "Must be an integer";
            } else if (points < 0) {
              errors.points = "Must greater then 0";
            }
            return errors;
          }}
          onSubmit={(values) => {
            mutation.mutate(parseInt(values.points));
          }}
        >
          <Form className="modalForm">
            <Input name="points" placeholder="0" type="number" />
            <Button type="submit">Confirm</Button>
          </Form>
        </Formik>
      </Modal.Content>
    </Modal>
  );
}
