import React from "react";
import { Divider } from "semantic-ui-react";

export default function TableInfo({
  content,
}: {
  content: string;
}): JSX.Element {
  return (
    <>
      <Divider style={{ marginTop: 0 }} />
      <div style={{ textAlign: "center" }}>{content}</div>
    </>
  );
}
