/* eslint-disable @typescript-eslint/ban-types */
import { createSlice } from "@reduxjs/toolkit";
import { RootState } from ".";

interface UserStateInterface {
  user: { email: string; username: string } | null;
}

const initialState: UserStateInterface = {
  user: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, { payload }) => {
      state.user = payload;
    },
  },
});

export const { setUser } = userSlice.actions;

export const userSelector = (
  state: RootState
): { email: string; username: string } | null => state.user.user;

export default userSlice.reducer;
