import React, { useState } from "react";
import { useQuery, useMutation } from "react-query";
import axios from "axios";
import { Button, Table, Pagination } from "semantic-ui-react";
import { Formik, Form } from "formik";
import { Input, Dropdown } from "components/Form";
import TableInfo from "components/TableInfo";
import dayjs from "dayjs";
import ActionDropdown from "./ActionDropdown";
import { usersModalEnum, levelOptions } from "constant";
import LevelModal from "./LevelModal";
import PointsModal from "./PointsModal";
import BalanceModal from "./BalanceModal";

interface UserInterface {
  username: string;
  email: string;
  level: number;
  points: number;
  createdAt: string;
  balances?: Record<string, unknown>;
}

const DEFAULT_PAGE_SIZE = 10;

export default function Users(): JSX.Element {
  const [search, setSearch] = useState<{
    username: string;
    email: string;
    level: string;
    page: number;
    page_size: number;
  }>({
    username: "",
    email: "",
    level: "",
    page: 1,
    page_size: DEFAULT_PAGE_SIZE,
  });
  const [modalOpen, setModalOpen] = useState(usersModalEnum.None);
  const [currentUser, setCurrentUser] = useState<UserInterface | null>(null);

  const { isLoading, data, refetch } = useQuery(["users", search], async () => {
    const { data } = await axios.get("search/users", {
      params: { ...search },
    });
    return data;
  });

  const pointsMutation = useMutation(
    (points) =>
      axios.post(`/users/${currentUser?.username}/addpoints`, {
        amount: points,
      }),
    {
      onSuccess: () => {
        refetch();
        setModalOpen(usersModalEnum.None);
      },
    }
  );

  const levelMutation = useMutation(
    (level) =>
      axios.post(`/users/${currentUser?.username}/setlevel`, {
        level,
      }),
    {
      onSuccess: () => {
        refetch();
        setModalOpen(usersModalEnum.None);
      },
    }
  );

  const balanceMutation = useMutation(
    (balance) =>
      axios.post(`/users/${currentUser?.username}/addbalance`, balance),
    {
      onSuccess: () => {
        refetch();
        setModalOpen(usersModalEnum.None);
      },
    }
  );

  const page = data?.page;
  const total = Math.ceil(data?.total / data?.pageSize);

  return (
    <>
      <Formik
        initialValues={{
          username: "",
          email: "",
          level: "",
        }}
        onSubmit={(values) => {
          setSearch({ ...values, page: 1, page_size: DEFAULT_PAGE_SIZE });
        }}
      >
        <Form className="searchWrapper">
          <label>Username</label>
          <Input name="username" />
          <label>Email</label>
          <Input name="email" />
          <label>Level</label>
          <Dropdown id="level" name="level" options={levelOptions} clearable />
          <Button type="submit">Search</Button>
        </Form>
      </Formik>
      {isLoading ? (
        <TableInfo content="Loading" />
      ) : !data || !data.items || data.items.length === 0 ? (
        <TableInfo content="No data" />
      ) : (
        <>
          <Table basic unstackable selectable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Username</Table.HeaderCell>
                <Table.HeaderCell>Email</Table.HeaderCell>
                <Table.HeaderCell>Level</Table.HeaderCell>
                <Table.HeaderCell>CreatedDate</Table.HeaderCell>
                <Table.HeaderCell>Points</Table.HeaderCell>
                <Table.HeaderCell>Balance</Table.HeaderCell>
                <Table.HeaderCell>Action</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {data.items.map(
                (
                  item: {
                    username: string;
                    email: string;
                    level: number;
                    points: number;
                    createdAt: string;
                    balances?: Record<string, unknown>;
                  },
                  index: number
                ) => (
                  <Table.Row key={index}>
                    <Table.Cell>{item.username}</Table.Cell>
                    <Table.Cell>{item.email}</Table.Cell>
                    <Table.Cell>{item.level}</Table.Cell>
                    <Table.Cell>
                      {dayjs(item.createdAt).format("YYYY/MM/DD HH:mm")}
                    </Table.Cell>
                    <Table.Cell>{item.points}</Table.Cell>
                    <Table.Cell>{`${
                      item.balances ? JSON.stringify(item.balances) : ""
                    }`}</Table.Cell>
                    <Table.Cell>
                      <ActionDropdown
                        setModalOpen={setModalOpen}
                        onFocus={() => {
                          setCurrentUser(item);
                        }}
                      />
                    </Table.Cell>
                  </Table.Row>
                )
              )}
            </Table.Body>
          </Table>
          <div className="paginationWrapper">
            <div style={{ marginLeft: "1rem" }}>total: {data?.total ?? 0}</div>
            <Pagination
              activePage={page}
              totalPages={total}
              onPageChange={(e, { activePage }) =>
                setSearch({ ...search, page: Number(activePage) })
              }
            />
          </div>
        </>
      )}
      <LevelModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        mutation={levelMutation}
        level={currentUser?.level}
      />
      <PointsModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        mutation={pointsMutation}
      />
      <BalanceModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        mutation={balanceMutation}
      />
    </>
  );
}
